.medical-body {
  padding: 0 64px;
}

.medical-body > .col > div {
  margin-bottom: 64px;
}

@media (max-width: 767px) {
  .medical-body {
    padding: 0 32px;
  }

  .medical-body > .col > div {
    width: 100vw;
  }
}

@media (min-width: 768px) {
  .medical-body {
    padding: 0 32px;
  }

  .medical-body > .col > div {
    width: 40vw;
  }
}

@media (max-width: 1099px) {
  .medical-body > .col > div {
    margin-bottom: 64px;
  }
}

@media (min-width: 1440px) {
  .medical-body {
    padding: 0 128px;
  }

  .medical-body > .col > div {
    margin-bottom: 128px;
    width: 528px;
  }
}
