.call-card-container {
  background-color: var(--color-white);
  padding: 64px;
  border-radius: 16px;
}

.call-card-container h3 {
  color: rgb(var(--color-black));
  font-family: var(--h3-font-bold);
  font-size: var(--h3-tablet-size);
  line-height: var(--h3-tablet-height);
  margin-bottom: 32px;
}

.email-card-container {
  margin-top: 64px;
}

@media (max-width: 767px) {
  .call-card-container {
    padding: 32px;
  }

  .numbers-containers {
    width: 100%;
  }
}

@media (max-width: 1099px) {
  .call-card-container h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
    margin-bottom: 32px;
  }
}

@media (min-width: 1440px) {
  .call-card-container h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
    margin-bottom: 64px;
  }
}
