.footer-margin {
  margin-top: 168px;
}

.less-margin {
  margin-top: 104px;
}

.foot-info {
  background-color: var(--color-black-05);
  padding: 64px 128px;
  border-bottom: 1px solid var(--color-black-25);
}

.foot-info h3 {
  color: rgb(var(--color-black));
  font-size: var(--h3-tablet-size);
  font-family: var(--h3-font-bold);
  line-height: var(--h3-tablet-height);
  margin: 0;
  padding: 0;
}

.foot-info h5 {
  color: rgb(var(--color-black));
  font-size: var(--h5-tablet-size);
  font-family: var(--h5-font-light);
  line-height: var(--h5-tablet-height);
  margin: 0;
  padding: 0;
}

.foot-icons a {
  color: rgb(var(--color-black));
  font-size: 28px;
  margin: 0 16px;
}

.foot-icons a:hover {
  color: var(--color-black-75);
}

.foot-icons a:active {
  color: var(--color-black-50);
}

.foot-button {
  padding-top: 16px;
}

.foot-copy {
  padding: 20px 0;
  margin: 0 32px;
  color: var(--color-black-75);
  font-size: var(--h6-size);
  font-family: var(--h6-font-regular);
  line-height: var(--h6-height);
}

.foot-copy a {
  text-decoration: none;
  color: rgb(var(--color-black));
}

.foot-copy a:hover {
  text-decoration: underline;
}

.foot-copy a:active {
  opacity: 0.75;
}

@media (max-width: 768px) {
  .foot-info {
    padding: 32px 44px;
  }
}

@media (max-width: 1099px) {
  .less-margin {
    margin-top: 36px;
  }

  .footer-margin {
    margin-top: 100px;
  }

  .foot-info h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
  }

  .foot-info h5 {
    font-size: var(--h5-phone-size);
    line-height: var(--h5-phone-height);
  }

  .foot-icons a {
    font-size: 22px;
  }
}

@media (max-width: 1439px) {
  .foot-copy {
    padding: 16px 0;
    line-height: var(--h6-phone-height);
    font-size: var(--h6-phone-size);
  }
}

@media (min-width: 1440px) {
  .less-margin {
    margin-top: 128px;
  }

  .footer-margin {
    margin-top: 256px;
  }

  .foot-info h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
  }

  .foot-info h5 {
    font-size: var(--h5-size);
    line-height: var(--h5-height);
  }

  .foot-icons a {
    font-size: 32px;
  }
}
