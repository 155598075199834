.top-card-container {
  margin: 100px 64px 168px;
}

.top-card-container h1 {
  font-size: var(--h1-tablet-size);
  line-height: var(--h1-tablet-height);
  font-family: var(--h1-font-bold);
  margin-bottom: 16px;
  background-image: var(--color-secondary-gradient);
  background-color: rgb(var(--color-secondary));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.top-card-container h3 {
  color: rgb(var(--color-black));
  font-family: var(--h3-font-light);
  font-size: var(--h3-tablet-size);
  line-height: var(--h3-tablet-height);
  margin-bottom: 0px;
}

@media (max-width: 1099px) {
  .top-card-container {
    margin: 64px 32px 100px;
  }

  .top-card-container h1 {
    font-size: var(--h1-phone-size);
    line-height: var(--h1-phone-height);
    margin-bottom: 8px;
  }

  .top-card-container h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
    margin-bottom: 0px;
  }
}

@media (min-width: 1440px) {
  .top-card-container {
    margin: 128px 128px 256px;
  }

  .top-card-container h1 {
    font-size: var(--h1-size);
    line-height: var(--h1-height);
    margin-bottom: 32px;
  }

  .top-card-container h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
    margin-bottom: 0px;
  }
}
