.contact-container {
  margin: 0 100px;
}

.map-container,
.call-container {
  margin-bottom: 100px;
}

@media (max-width: 1099px) {
  .contact-container {
    margin: 0 32px;
  }

  .map-container,
  .call-container {
    margin-bottom: 64px;
  }
}

@media (min-width: 1440px) {
  .contact-container {
    margin: 0 128px;
  }

  .map-container,
  .call-container {
    margin-bottom: 128px;
  }
}
