.home-container {
  margin-top: 100px;
  padding: 0 100px;
}

.call-action-container {
  margin: 0 0 168px 0;
}

.clinic-img {
  margin-left: 32px;
  border-radius: 16px;
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.home-container h1 {
  background-image: var(--color-secondary-gradient);
  background-color: rgb(var(--color-secondary));
  background-size: 100%;
  font-family: var(--h1-font-bold);
  font-size: var(--h1-tablet-size);
  line-height: var(--h1-tablet-height);
  text-align: center;
  margin-bottom: 8px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.home-container h3 {
  font-size: var(--h3-tablet-size);
  line-height: var(--h3-tablet-height);
  font-family: var(--h3-font-light);
  text-align: center;
  color: rgb(var(--color-black));
  margin-bottom: 32px;
}

.about-container {
  margin-bottom: 100px;
}

.mision-container {
  margin-right: 50px;
}

.vision-container {
  margin-left: 50px;
}

@media (max-width: 1099px) {
  .call-action-container {
    margin: 0 0 100px 0;
  }

  .about-container {
    margin-bottom: 64px;
  }

  .mision-container {
    margin-right: 32px;
  }

  .vision-container {
    margin-left: 32px;
  }
}

@media (max-width: 991px) {
  .mision-container {
    margin: 0;
    margin-bottom: 64px;
  }

  .vision-container {
    margin: 0;
  }
}

@media (max-width: 1099px) {
  .home-container {
    margin-top: 64px;
    padding: 0 32px;
  }

  .home-container h1 {
    font-size: var(--h1-phone-size);
    line-height: var(--h1-phone-height);
    margin-bottom: 0;
  }

  .home-container h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
    margin-bottom: 16px;
  }

  .clinic-img {
    height: 240px;
    margin-left: 0;
  }
}

@media (min-width: 1440px) {
  .call-action-container {
    margin: 0 0 256px 0;
  }

  .home-container {
    margin-top: 120px;
    padding: 0 128px;
  }

  .home-container h1 {
    font-size: var(--h1-size);
    line-height: var(--h1-height);
    margin-bottom: 16px;
  }

  .home-container h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
    margin-bottom: 64px;
  }

  .clinic-img {
    height: 380px;
    margin-left: 0;
  }
}
