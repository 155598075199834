.call-button-container {
  background-color: var(--color-black-05);
  padding: 16px 32px;
  border-radius: 16px;
}

.call-button-container h4 {
  color: rgb(var(--color-black));
  font-family: var(--h4-font-regular);
  font-size: var(--h4-tablet-size);
  line-height: var(--h4-tablet-height);
  margin: 0;
  word-wrap: break-word;
}

.call-button-container div {
  margin-left: 32px;
  border-left: 1px solid var(--color-black-50);
}

.call-button-container a,
.call-button-container span {
  margin-left: 16px;
  font-size: 38px;
  color: var(--color-black-75);
}

.call-button-container a:hover,
.call-button-container span:hover {
  color: rgb(var(--color-black));
  cursor: pointer;
}

.call-button-container a:active,
.call-button-container span:active {
  color: var(--color-black-50);
}

@media (max-width: 767px) {
  .call-button-container div {
    margin-left: 0;
    border: none;
  }

  .call-button-container {
    padding: 8px 16px;
  }

  .call-button-container a,
  .call-button-container span {
    margin: 0 8px;
  }
}

@media (max-width: 1099px) {
  .call-button-container h4 {
    font-size: var(--h4-phone-size);
    line-height: var(--h4-phone-height);
  }

  .call-button-container a,
  .call-button-container span {
    font-size: 28px;
  }
}

@media (min-width: 1440px) {
  .call-button-container h4 {
    font-size: var(--h4-size);
    line-height: var(--h4-height);
  }
}
